@use './src/variables' as *;




.rounded-border {
  border: 1px solid black;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  height: 38px;
}

.deposit_price_text {

  font-size: 0.7em;

}

.offer-deposit-detail{
  position: absolute;
  background: white;
  padding: 10px 20px 10px 20px;
  top:-265px;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
  width: 300px;
  font-size: 14px;
  font-weight: 300;
  right: 0px;

  @media (max-width: 760px) {
    top:-275px;
  }

  .offer-deposit-section{
    padding: 10px 0px 10px 0px;
  }

  .offer-deposit-line{
    display: flex;
    justify-content: space-between;
    margin-top: 11px;
  }

  label {
    font-weight: 500;

  }
}

.offer-deposit-detail-s{
  top:-170px;

  @media (max-width: 760px) {
    top: -190px;
  }

}

.monthly{
  top: -110px;

  @media (max-width: 760px) {
    right: 0px;
    top: -70px;
  }

}

.price-section {

  display: flex;
  align-items: center;
  padding: 15px 25px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  .price-section-buttons {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .button {
      margin-right: 1rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;

      @media screen and (min-width: 425px) {
        font-size: 1rem;
      }

      img {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .only{
    width: 100%;
  
  }

   
  .price-wrapper {
    display: flex;
    width: 100%;


    @media (max-width: $screen-small) {
      width: 100%;
      justify-content: end;
    }

    .price {
      align-self: center;
      font-size: 14px;
      text-decoration: line-through;
    }

    .sale-price {
      font-size: 30px;
      font-weight: bold;
      // color: $main;
    }

    span {
      display: flex;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
        align-self: flex-end;
      }
    }
  }

  .price-wrapper-s{
    @media (min-width: $screen-small) {
      height: 62px;
    }
  }

  .holiday-card-price-calendar {
    position: absolute;
    top: 215px;
    left: 0;
    background: $white;
    z-index: 10;
    padding: 20px 0;
    border-radius: 10px;
    box-shadow: 4px 6px 12px 0px rgb(0 0 0 / 20%);
    border: 1px solid $main;
    margin: 1rem 0;
    width: 100%;

    button {
      width: 60px;
    }

    @media screen and (min-width: 768px) {
      top: 110px;
      right: 0px;
    }

    @media screen and (min-width: 1024px) {
      top: 110px;
    }

    @media screen and (min-width: 1280px) {
      right: 0px;
    }

    .holiday-card-price-calendar__close {
      background: $main;
      color: $white;
      position: absolute;
      top: -8px;
      right: -8px;
      width: 25px;
      height: 25px;
      appearance: none;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  @media (max-width: $screen-small) {
    // flex-direction: column;
    // align-items: end;
    padding: 15px;

    .price-wrapper {
      position: relative;
      display: flex;
      justify-content: end;
      margin-bottom: 20px;

      .sale-price {

        img {
          transform: scale(1.5);
        }
      }
    }

    button {
      width: 100%;
    }

    .deposit_mobile{
      display: none;
    }


  }

  .deposit_mobile{
    display: none;
  }
  
  @media (max-width: $screen-small) {
    .deposit_mobile{
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    .deposit_desk{
      display: none;
    }

  }
}

.deposit_desk{
  display: flex;
  margin-bottom: 20px;
  justify-content: end !important;
  height: 48px;
}

.price-btn-s{
  width: 150px;
  padding: 12px 10px;
}

.price-btn{
    padding: 12px 10px;
}

.deposit_mobile{
  justify-content: end;
}

.downer{
  rotate: 30deg;
}


i {
  display: inline-block;
  font-style: normal;
  position: relative;
  cursor: pointer;
}

/* Additional formatting for arrow icon */
i.arrow {
    /* top: 2pt; Uncomment this to lower the icons as requested in comments*/
    transition:all 0.5s ease-out;
    width: 0.6em;
    height: 0.6em;
    border-right: 0.2em solid black;
    border-top: 0.2em solid black;
    transform: rotate(135deg);
    margin-left: 8px;
    margin-bottom: 4px;

}

i.arrowon{
  transition:all 0.5s ease-out;
  width: 0.6em;
  height: 0.6em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(315deg);
  margin-left: 8px;
  margin-bottom: 0px;
}