@import '../../variables';

// mobile and desktop

.input-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  width: 100%;
  height: 48px;
  border: solid 1px $secondary;
  border-radius: 7px;
  cursor: pointer;

  &.focus {
    border: 1px solid red;
  }

  .input-field--title {
    display: none;
  }

  .input-field--value {
    .default-value {
      color: $main;
    }

    .selected-value {
      color: $main;
    }
  }

  .icon-wrapper {
    width: 48px;
    height: 48px;
    border-left: 1px solid $secondary;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// desktop styles
@media (min-width: $screen-small) {
  .input-field-wrapper {
    padding: 9px 14px;
    border: solid 1px $secondary;
    display: flex;
    align-items: start;
    border-radius: 7px;
    font-size: 16px;
    cursor: pointer;
    min-width: 148px;
    height: auto;

    .focus {
      border: solid 1px red;
    }

    .input-field--title {
      display: block;
      margin: 0 0 8px 0;
      color: $main;
      font-weight: bold;
      pointer-events: none;
      white-space: nowrap;
    }

    .input-field--value {
      pointer-events: none;

      .default-value {
        color: $grey-blue;
      }

      .selected-value {
        color: $main;
      }
    }

    .icon-wrapper {
      width: 48px;
      height: 48px;
      border-left: none;
      display: none;
    }
  }
}

// mobile only styles.
@media (max-width: $screen-small) {
  .input-field-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    width: 100%;
    height: 48px;
    border: solid 1px $secondary;
    border-radius: 7px;
    cursor: pointer;
    padding: 0px;
    padding-left: 10px;

    .focus {
      border: solid 1px red;
    }

    .input-field--title {
      display: none;
    }

    .input-field--value {
      .default-value {
        color: $main;
      }

      .selected-value {
        color: $main;
      }
    }

    .icon-wrapper {
      width: 48px;
      height: 48px;
      border-left: 1px solid $secondary;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/**
 * When input is within a .focus element the border
 * should be highlighted.
 */

.focus {
  .input-field-wrapper {
    border: solid 1px red;
  }
}
