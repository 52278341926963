@use '../../variables' as *;

.booking-confirmed-page {
  margin: 0 auto;
  max-width: 600px;

  &--failed {
    padding-top: 1rem;
    padding-bottom: 3rem;
    text-align: center;
  }

  .booking-confirmed-page-wrapper {
    margin: 0 20px;

    .success-icon-wrapper {
      display: none;
    }

    &--header {
      // color: $main;

      .booking-title {
        font-size: 20px;
        font-weight: 500;
        margin: 40px 0 20px 0;
        text-transform: capitalize;
      }

      .booking-title--normal {
        text-transform: none;
      }

      .booking-emil {
        margin: 20px 0;
      }

      .booking-emil--failed {
        line-height: 30px;
      }

      .booking-text {
        line-height: 30px;
      }

      a {
        // color: $main;
      }
    }

    &--body {
      .booking-summary-list {
        margin-bottom: 30px;

        &--item {
        }

        .booking-summary-list--item + .booking-summary-list--item {
          margin-top: 25px;
        }
      }
    }
  }
}

@media (max-width: $screen-small) {
  .booking-confirmed-page {
    .booking-confirmed-page-wrapper {
      margin: 0;
      .success-icon-wrapper {
        display: block;
      }

      &--header {
        padding: 0 20px 20px 20px;
        margin: 0 0 20px 0;
        border-bottom: solid 1px white;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 10px 20px -15px #111;

        .booking-title {
          margin-top: 30px;
        }
      }

      &--body {
        margin: 0 20px;
      }
    }
  }
}

.debit-list li {
  padding: 15px;
  .sub-item {
    list-style-type: circle;
  }  
}

