@use './src/variables';

.filter-wrapper {
  font-family: variables.$base-font;

  &--title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;

    img {
      width: 22px;
      height: 22px;
    }
  }

  &--filters-wrapper {
    .filter-item {
      display: grid;
      grid-template-columns: 20px 1fr 50px;
      font-size: 16px;
      font-weight: 300;
      text-align: left;

      &--checkbox {
        border: 1px solid rgba(1, 68, 123, 0.2);
        opacity: 0.2;
        margin: 0;
        cursor: pointer;
      }

      .labelOver {
        font-size: 12px;
        margin-left: 12px;

        @media (max-width: variables.$screen-medium) {
          font-size: 10px;
          margin-left: 8px;
        }
      }

      .stars {
        display: flex;
        padding: 0 20px;
        align-items: center;

        .star {
          width: 18px;
          height: 18px;
        }

        .star + .star {
          margin-left: 4px;
        }

        @media (max-width: variables.$screen-medium) {
          padding: 0 10px;
        }
      }

      .fullrate {
        height: 16px;
        padding: 0 20px;
      }

      &--title {
        padding: 0 20px;
      }

      &--numberItems {
        text-align: end;
      }
    }

    .filter-item + .filter-item {
      margin-top: 15px;
      text-align: left;
    }
  }
}
