@import '../../variables';

.hwt-header {
  background-color: #fdfdfd;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.wrapper {
  font-family: 'GothamSSm', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  max-width: 1400px;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .content-wrapper {
    padding: 0 4rem;
    margin-bottom: 32px;
  }
}

.mobile-wrapper {
  display: none;
}

.main-search {
  font-weight: 500;
}

.main-search,
.menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 1024px) {
  .main-search {
    display: none;
  }
}

.header-icons-section > .heart-icon-wrapper {
  margin-right: 36px;
}

.header-icons-section .menu-wrapper {
  border: 1px solid rgba(1, 68, 123, 0.2);
  padding: 16px 20px;
  border-radius: 40px;
}

.header-icons-section .menu-wrapper .header-icon + .header-icon {
  margin-left: 14px;
}

.search-navigation-link {
  width: 100%;
  text-decoration: none;
}

@media (max-width: $screen-small) {
  .wrapper {
    display: none;
  }

  .mobile-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0;
    flex-wrap: wrap;
  }

  .mobile-wrapper > .main-search,
  .header-logo,
  .header-icons-section,
  .menu-wrapper {
    padding: 10px 0;
  }
  .mobile-wrapper > .header-icons-section > .heart-icon-wrapper {
    margin-right: 0;
  }

  .content-wrapper {
    padding: 0;
  }
}

.header-icons-section-wrap {
  justify-content: end;
  flex-wrap: wrap;
}
